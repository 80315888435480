<template>
  <Merchants />
</template>
<script>
  import Merchants from "@/components/Merchants/Merchants";
  export default {
    components: {
      Merchants,
    },
  };
</script>
